<template>
  <div>
    <div class="status">
      <p class="user">
        Usuário:
        <span class="label-user"
        ><em class="bi bi-person-fill"></em> {{ log.user || 'Nenhum' }}</span>
      </p>
      <p class="last-edition">
        Deletado há
        <span class="label-edition">{{ log.deletedAt || '0d' }}</span>
      </p>
    </div>
    <slot />
  </div>
</template>
<script>
import accessLogService from '@/services/access.log.service';

export default {
  name: 'EpLogAcessDeleted',
  props: {
    id: { required: false },
    module: { required: false, type: String }
  },
  filters: {
    date(value) {
      if (!value) {
        return '';
      }
      return new Intl.DateTimeFormat('pt-BR').format(new Date(value));
    }
  },
  data() {
    return {
      log: {
        user: null,
        deletedAt: null
      }
    };
  },
  watch: {
    'id'() {
      this.logging();
    }
  },
  mounted() {
    this.logging();
  },
  methods: {
    logging() {
      if (this.id) {
        accessLogService
          .latest(
            'DELETE',
            this.module,
            this.id
          )
          .then(response => {
            let result = response.data.data.results;
            if (result) {
              this.log.user = result.usuario.nome_usuario;
              this.log.deletedAt = result.updated_at;
            }
          });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.status {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .pendente {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    em {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .label-status {
    margin: auto;
    display: inline-flex;
    background: #e9edef;
    border-radius: 16px;

    em {
      width: 24px;
      height: 24px;
      font-size: 20px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
    }
  }

  .user {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .label-user {
    margin-left: 10px;
    background: #e9edef;
    border-radius: 16px;
    padding: 2px 8px 2px 2px;
    height: 30px;
    display: inline-flex;
    align-items: center;

    em {
      width: 24px;
      height: 24px;
      background: #373c3f;
      color: #fff;
      font-size: 28px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
      margin-right: 6px;
    }
  }

  .last-edition {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;

    .label-edition {
      min-width: 30px;
      padding: 2px 2px 2px 2px;
      height: 30px;
      background: #e9edef;
      border-radius: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.icon-status {
  background: #872240;
  color: #fff;
}
</style>
