var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"input form-floating"},[((_vm.type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model[_vm.label]),expression:"model[label]"}],ref:"input",class:{
          inputActive: _vm.arrowCounter === -1,
          'form-control input-select': true,
          'invalid is-invalid': _vm.hasError,
      },staticStyle:{"background-image":"none"},attrs:{"autocomplete":"off","placeholder":_vm.placeholderName,"aria-label":_vm.ariaLabelName,"id":_vm.idName,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model[_vm.label])?_vm._i(_vm.model[_vm.label],null)>-1:(_vm.model[_vm.label])},on:{"input":_vm.debounceSearch,"click":() => {
          _vm.showoptions = !_vm.showoptions;
        },"keydown":_vm.onKeydown,"change":function($event){var $$a=_vm.model[_vm.label],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.model, _vm.label, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.model, _vm.label, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.model, _vm.label, $$c)}}}},'input',_vm.$attrs,false)):((_vm.type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model[_vm.label]),expression:"model[label]"}],ref:"input",class:{
          inputActive: _vm.arrowCounter === -1,
          'form-control input-select': true,
          'invalid is-invalid': _vm.hasError,
      },staticStyle:{"background-image":"none"},attrs:{"autocomplete":"off","placeholder":_vm.placeholderName,"aria-label":_vm.ariaLabelName,"id":_vm.idName,"type":"radio"},domProps:{"checked":_vm._q(_vm.model[_vm.label],null)},on:{"input":_vm.debounceSearch,"click":() => {
          _vm.showoptions = !_vm.showoptions;
        },"keydown":_vm.onKeydown,"change":function($event){return _vm.$set(_vm.model, _vm.label, null)}}},'input',_vm.$attrs,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model[_vm.label]),expression:"model[label]"}],ref:"input",class:{
          inputActive: _vm.arrowCounter === -1,
          'form-control input-select': true,
          'invalid is-invalid': _vm.hasError,
      },staticStyle:{"background-image":"none"},attrs:{"autocomplete":"off","placeholder":_vm.placeholderName,"aria-label":_vm.ariaLabelName,"id":_vm.idName,"type":_vm.type},domProps:{"value":(_vm.model[_vm.label])},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.model, _vm.label, $event.target.value)},_vm.debounceSearch],"click":() => {
          _vm.showoptions = !_vm.showoptions;
        },"keydown":_vm.onKeydown}},'input',_vm.$attrs,false)),_c('img',{staticClass:"fake-select",class:_vm.showoptions ? 'active' : '',attrs:{"src":require("../../assets/img/Vector.svg"),"alt":""},on:{"click":() => {
          _vm.showoptions = !_vm.showoptions;
        }}}),_c('label',{attrs:{"for":_vm.idName}},[_vm._v(_vm._s(_vm.labelName))]),(_vm.hasError)?_c('small',{staticClass:"text-danger mt-2",staticStyle:{"font-size":"12px","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]),(_vm.showoptions)?_c('div',{staticClass:"options"},[(!_vm.isOptionValid && _vm.model[_vm.label] && !_vm.notCreateItem)?_c('p',{staticClass:"pointer create-option",on:{"click":_vm.createOption}},[_c('strong',{staticClass:"fw-normal"},[_vm._v("Criar")]),_vm._v(" "+_vm._s(_vm.model[_vm.label])+" ")]):_vm._e(),(!_vm.labelDescription)?_c('p',{staticClass:"small-text"},[_c('u',[_vm._v("Selecione")]),_vm._v(" ou crie um(a) novo(a) "+_vm._s(_vm.fieldName)+" ")]):_c('p',{staticClass:"small-text"},[_vm._v(" "+_vm._s(_vm.labelDescription)+" ")]),_c('ul',{staticClass:"list"},[_vm._l((_vm.options),function(option,index){return _c('li',{key:option[_vm.id],ref:`option_${index}`,refInFor:true,class:{ pillActive: _vm.arrowCounter === index },on:{"click":function($event){return _vm.selectedOption(option)}}},[(option.cor !== undefined)?_c('span',{staticClass:"pill",style:({
            background: option['cor'] || 'rgb(233, 237, 239)'
          })},[_vm._v(" "+_vm._s(option[_vm.label])+" ")]):_c('span',{staticClass:"pill"},[_vm._v(_vm._s(option[_vm.label]))])])}),(!_vm.options.length && this.model[this.label])?_c('li',[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Nenhum resultado encontrado.")])]):_vm._e()],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }