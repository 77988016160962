<template>
  <h2 class="accordion-header mb-2">
    <button
      type="button"
      class="accordion-button"
    >
      <div class="status">
        <div v-if="!skippedStatus">
          <p v-if="!status" class="pendente">
            <span class="label-status">
              <em class="bi bi-exclamation-lg"></em
              ></span>
            &nbsp;Pendente
          </p>
          <p v-else class="pendente">
          <span class="label-status">
            <em class="bi bi-check icon-status"></em>
          </span>
            &nbsp;Configurado
          </p>
        </div>
        <p class="user">
          Usuário:
          <span class="label-user"
          ><em class="bi bi-person-fill"></em> {{ log.user || 'Nenhum' }}</span
          >
        </p>
        <p v-if="skippedStatus" class="pendente">
          Criado em: <span class="label-status mx-2 p-2">{{ log.createdAt || new Date() | date }}</span>
        </p>
        <p class="last-edition">
          Última edição
          <span class="label-edition">{{ log.updatedAt || '0d' }}</span>
        </p>
      </div>
      <slot />
    </button>
  </h2>
</template>
<script>
import accessLogService from '@/services/access.log.service';

export default {
  name: 'EpAccordionFakeHeaderStatus',
  props: {
    skippedStatus: { required: false, type: Boolean },
    createdAt: { required: false, type: String },
    status: { required: false, type: Boolean },
    id: { required: false },
    primaryKey: { required: false },
    column: { required: false },
    filter: { required: false },
    method: { required: false, type: String },
    module: { required: false, type: String },
  },
  filters: {
    date(value) {
      if (!value) {
        return '';
      }
      return new Intl.DateTimeFormat('pt-BR').format(new Date(value));
    }
  },
  data() {
    return {
      log: {
        user: null,
        updatedAt: null,
        createdAt: null
      }
    };
  },
  watch: {
    'id'() {
      this.logging();
    },
    'createdAt'(value) {
      this.log.createdAt = value;
    }
  },
  mounted() {
    this.logging();
  },
  methods: {
    logging() {
      if (this.id || this.filter) {
        accessLogService
          .latest(
            this.method,
            this.module,
            this.id,
            this.primaryKey,
            this.column,
            this.filter
          )
          .then(response => {
            let result = response.data.data.results;
            if (result) {
              this.log.user = result.usuario.nome_usuario;
              this.log.updatedAt = result.updated_at;
            }
          });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.status {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .pendente {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    em {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .label-status {
    margin: auto;
    display: inline-flex;
    background: #e9edef;
    border-radius: 16px;

    em {
      width: 24px;
      height: 24px;
      font-size: 20px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
    }
  }

  .user {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .label-user {
    margin-left: 10px;
    background: #e9edef;
    border-radius: 16px;
    padding: 2px 8px 2px 2px;
    height: 30px;
    display: inline-flex;
    align-items: center;

    em {
      width: 24px;
      height: 24px;
      background: #373c3f;
      color: #fff;
      font-size: 28px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
      margin-right: 6px;
    }
  }

  .last-edition {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;

    .label-edition {
      min-width: 30px;
      padding: 2px 2px 2px 2px;
      height: 30px;
      background: #e9edef;
      border-radius: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.accordion-item {
  border: 0 !important;
}

.accordion,
.accordion-button,
.accordion-item {
  border-radius: 0 !important;
}

.accordion-button {
  font-size: 20px;
  font-weight: 700;
  color: #2d3032;
  border-left: 8px solid #c0c5c8;
  margin-bottom: 4px;
  background-color: white;
  border-top: 1px solid #e9edef;
  border-right: 1px solid #e9edef;
  border-bottom: 1px solid #e9edef;
  box-shadow: none;
  display: inline-block;
  position: relative;

  &:disabled {
    color: #C0C5C8;
    border-left: 8px solid #e6e8e9 !important;
  }

  .span-header {
    color: #5e666a;
    margin-top: 8px;
  }

  &::after {
    content: '';
  }

  &:not(.collapsed) {
    &::after {
      content: '';
    }
  }

  &:focus {
    box-shadow: unset;
  }

  span {
    font-weight: 400;
    font-size: 12px;
  }
}

.icon-status {
  background: #872240;
  color: #fff;
}
</style>
