import { mainAxios } from '@/plugins/axios';

class TaxRuleService {
  async getTaxesByUrl(url, order) {
    const orderField = `?order_field=cod_regra_fiscal&order=${order}&`;
    const splitString = url.split('?');
    const completeUrl = `${splitString[0]}${orderField}${splitString[1]}`;
    const response = await mainAxios.get(`${completeUrl}`);
    return response.data;
  }
  async getPerPage(perPage, order) {
    const response = await mainAxios.get(
      `/regras-fiscais?order_field=cod_regra_fiscal&order=${order}&per_page=${perPage}`
    );
    return response.data;
  }
  async getTaxRuleById(id) {
    const response = await mainAxios.get(`/regras-fiscais/${id}`);
    return response.data;
  }
  async getTaxes(query) {
    const response = await mainAxios.get(query);
    return response.data;
  }
  async list(pageUrl, perPage, limit, paginate, search = '', type = '') {
    const config = {
      transformResponse: [
        (data) => {
          let response = JSON.parse(data);
          response.data.results = response.data.results.map((value) => {
            return {
              cod_regra_fiscal: value.cod_regra_fiscal,
              nome_regra_fiscal: value.nome_regra_fiscal
            };
          });
          return response;
        }
      ]
    };
    const { data } = await mainAxios.get(
      `${pageUrl}&per_page=${perPage}&paginate=${paginate}&limit=${limit}&search=${search}&type=${type}`,
      config
    );
    return data;
  }
  async search(term) {
    const response = await mainAxios.get(`/regras-fiscais?search=${term}`);
    return response.data;
  }
  async createTax(payload) {
    const response = await mainAxios.post(`/regras-fiscais`, payload);
    return response.data;
  }
  async editTax(id, payload) {
    const response = await mainAxios.put(`/regras-fiscais/${id}`, payload);
    return response.data;
  }
  async deleteTaxRule(id) {
    const response = await mainAxios.delete(`/regras-fiscais/${id}`);
    return response.data;
  }
  async listIcms() {
    const response = await mainAxios.get(`/icms?paginate=false`);
    return response.data;
  }
  async listIpi() {
    const response = await mainAxios.get(`/ipi?paginate=false`);
    return response.data;
  }
  async listPisCofins() {
    const response = await mainAxios.get(`/pis-cofins?paginate=false`);
    return response.data;
  }
  async listCfop() {
    const response = await mainAxios.get(`/cfop?paginate=false`);
    return response.data;
  }
}

export default new TaxRuleService();
