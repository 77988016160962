import router from '@/router';
import productService from '@/services/product.service';

export const product = {
  namespaced: true,
  state: {
    products: {
      links: {},
      meta: {},
      results: []
    },
    editedProduct: {},
    queryString: undefined,
    url: undefined
  },
  mutations: {
    FILL_PRODUCTS(state, products) {
      state.products = products;
    },
    EDIT_PRODUCT(state, product) {
      state.editedProduct = product;
    },
  },
  actions: {
    async getProducts({ commit }, queryString) {
      const products = await productService.getListProducts(queryString);
      products.code === 200 ? commit('FILL_PRODUCTS', products.data) : null;
    },
    async editProduct({ commit }, productId) {
      const editProd = await productService.getProductById(productId);
      editProd.code === 200
        ? commit('EDIT_PRODUCT', editProd.data.results)
        : null;
    }
  },
  getters: {
    products: (state) => state.products,
    url: (state) => state.url,
    queryString: (state) => state.queryString
  }
};
