<script>
export default {
  name: 'EpPaginateResults',
  props: ['meta', 'perPage'],
  computed: {
    model: {
      get() {
        return this.perPage;
      },
      set(value) {
        this.$emit('update:perPage', value);
      }
    }
  },
};
</script>

<template>
  <div
    class="d-flex align-items-center justify-content-end"
  >
    <p class="m-0 text-results">
      Resultados: {{ meta.from }} - {{ meta.to }} de
      {{ meta.total }}
    </p>
    <select
      id="select-pages"
      class="form-select select-pages"
      v-model="model"
    >
      <option value="10">10</option>
      <option value="15">15</option>
      <option value="25">25</option>
      <option value="50">50</option>
    </select>
  </div>
</template>

<style scoped lang="scss">
.text-results {
  font-size: 11px;
  font-weight: 450;
  margin-right: 5px!important;
}
.box-pagination-results{
  right: 0;
}
.select-pages {
  width: 75px;
  height: 42px;
  background-color: #fff;
  border: 1px solid #c0c5c8;
  font-weight: 700;
  margin-right: 10px;
  border-radius: 8px;

  option {
    font-weight: 700;
  }
}
</style>
