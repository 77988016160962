<template>
  <div class="row justify-content-end mt-4 mb-4">
    <div class="col" v-if="meta.total"></div>
    <div class="col-auto" v-if="meta.total">
      <nav>
        <ul class="pagination" v-if="meta.total > meta.per_page">
          <li
            class="page-item"
            @click="prev"
            style="cursor: pointer">
            <a
              class="page-link"><em class="bi bi-chevron-left"></em></a>
          </li>
          <li
            v-for="(v, k) in model"
            :key="k"
            class="page-item"
            @click="change(v.url)"
            style="cursor: pointer"
          >
            <a
              v-if="v.label === '...'"
              class="page-link disabled"
            >...</a>
            <a
              v-if="!isNaN(parseInt(v.label))"
              :class="v.active ? 'active' : ''"
              class="page-link"
            >{{ v.label }}</a>
          </li>
          <li
            class="page-item"
            @click="next"
            style="cursor: pointer">
            <a
              class="page-link"><em class="bi bi-chevron-right"></em></a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col" v-if="meta.total">
      <slot></slot>
    </div>
  </div>
</template>
<script>

export default {
  name: 'EpPaginate',
  props: ['meta'],
  computed: {
    model: {
      get() {
        let paginate = [...this.meta.links];

        if (
          !!paginate.length
        )
        {
          paginate.shift();
          paginate.pop();
        }

        return paginate;
      }
    }
  },
  methods: {
    prev() {
      let links = this.meta.links
      if (links[0].url) {
        this.$emit('update:page', links[0].url);
      }
    },
    next() {
      let links = this.meta.links
      let last = links[links.length - 1];
      if (last.url) {
        this.$emit('update:page', last.url);
      }
    },
    change(value) {
      if (value) {
        this.$emit('update:page', value);
      }
    }
  }
}

</script>
<style scoped lang="scss">
.pagination {
  .page-item {
    &:first-child,
    &:last-child {
      .page-link {
        background: transparent;

        &:hover {
          color: #2d3032;
        }
      }
    }

    .active {
      background: #373c3f;
      color: #fff;
    }
  }

  .page-link {
    border: 0;
    padding: 2px 11px;
    width: 32px;
    height: 32px;
    background: rgba(226, 230, 232, 0.4);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #4b5053;
    margin: 4px;
    line-height: 2;

    &:hover {
      background: #373c3f;
      color: #fff;
    }
  }
}

</style>
