import { mainAxios } from "@/plugins/axios";

class LocaleService {
  async getCountry() {
    const response = await mainAxios.get(`/estados?paginate=false`);
    return response.data;
  }
  async getStates() {
    const response = await mainAxios.get(`/estados?paginate=false`);
    return response.data;
  }
  async getCityByStateId(id, search) {
    const response = await mainAxios.get(`/estados/${id}/cidades?paginate=false&search=${search}`);
    return response.data;
  }
}

export default new LocaleService();
