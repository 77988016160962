import { mainAxios } from '@/plugins/axios';

class accessLogService {
  async latest(method, module, id, primaryKey = null, column = null, filter = null) {
    return await mainAxios.get(
      `log-acesso/latest?metodo=${method}&modulo=${module}&id=${id}&primary_key=${primaryKey}&column=${column}&filter=${filter}`
    );
  }
}

export default new accessLogService();
