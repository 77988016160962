var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:['section', {
    'vh-100':
      _vm.$route.name === 'manage-price-table' ||
      _vm.$route.name === 'import-products-item' ||
      _vm.$route.name === 'chat' ||
      _vm.$route.name === 'calendar'
    }
  ],attrs:{"id":"app"}},[_c('div',{class:['d-flex', {
    'overflow-hidden': _vm.$route.name !== 'calendar'
  }]},[(!_vm.$route.meta.hideNavbar)?_c('MenuSidebar'):_vm._e(),_c('router-view',{attrs:{"broadcast":_vm.broadcast}})],1),(!_vm.$route.meta.hideNavbar && _vm.$route.path === '/home')?_c('div',{staticClass:"col-12 align-content-center position-absolute bottom-0"},[_vm._m(0)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 mx-lg-auto mb-3"},[_c('div',{staticClass:"card rounded-2",staticStyle:{"height":"60px","min-width":"390px","max-width":"500px"}},[_c('div',{staticClass:"row d-flex my-auto"},[_c('div',{staticClass:"col mx-2 my-auto"},[_vm._v(" Fale com o Fino ")]),_c('a',{staticClass:"btn btn-primary open-chat mx-3",attrs:{"type":"button","href":"/chat"}},[_vm._v(" Iniciar conversa "),_c('em',{staticClass:"bi bi-chat"})])])])])
}]

export { render, staticRenderFns }