import router from '@/router';
import AuthService from '@/services/auth.service';

export const auth = {
  namespaced: true,
  state: {
    fail: false,
    token: '',
    logged: {}
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    loginSuccess(state, logged) {
      state.logged = logged;
    },
    logout(state) {
      state.logged = null;
    }
  },
  actions: {
    login({ commit }, logged) {
      commit('loginSuccess', logged);
      commit('SET_TOKEN', logged['access_token']);
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    abilities({ commit }) {
      commit('loginSuccess', JSON.parse(sessionStorage.getItem('logged')));
    }
  },
  getters: {
    logged: (state) => state.logged,
    fail: (state) => state.fail
  }
};
