import TaxRuleService from '@/services/taxRule.service';

export const taxRule = {
  namespaced: true,
  state: {
    taxRules: {
      links: {},
      meta: {},
      results: []
    },
    icms: {},
    ipi: {},
    pisCofins: {},
    cfop: {},
    newTax: null,
    orderSelected: 'asc'
  },
  mutations: {
    FILL_TAX_RULES(state, taxRules) {
      state.taxRules = taxRules;
    },
    FILL_NEW_TAX(state, newTax) {
      state.newTax = newTax;
    },
    DELETE_TAX(state, tax) {
      state.taxRules = tax;
    },
    FILL_ICMS(state, icms) {
      state.icms = icms;
    },
    FILL_IPI(state, ipi) {
      state.ipi = ipi;
    },
    FILL_PIS_COFINS(state, pisCofins) {
      state.pisCofins = pisCofins;
    },
    FILL_CFOP(state, cfop) {
      state.cfop = cfop;
    },
  },
  actions: {
    async getTaxRule({ commit }, id) {
      const response = await TaxRuleService.getTaxRuleById(id);
      return response.code === 200
        ? commit('FILL_TAX_RULE', response.data.results)
        : null;
    },
    async createTax({ commit }, payload) {
      return TaxRuleService.createTax(payload)
        .then((response) => {
          commit('FILL_NEW_TAX', response.data);

        })
        .catch((error) => {
          Toast.fire({
            title: 'Atenção',
            html: `
          Erro ao cadastrar Regra Fiscal! Por favor, tente novamente.<br>
          <span style="font-size: 12px">- ${error.response.data.message}</span>
          `,
            icon: 'error',
            width: 600,
            showCloseButton: true,
            timerProgressBar: false
          });
          throw error.response.data;
        });
    },
    async editTaxRule({ commit }, { id, payload }) {
      return TaxRuleService.editTax(id, payload)
        .then(() => {
          Toast.fire('Regra fiscal atualizada com sucesso!', '', 'success');
        })
        .catch((error) => {
          Toast.fire('Erro ao atualizar Regra fiscal!', '', 'error');
        });
    },
    async changeTaxPage({ commit }, payload) {
      if (payload.url) {
        const taxes = await TaxRuleService.getTaxesByUrl(
          payload.url,
          this.state.taxRule.orderSelected
        );
        taxes.code === 200 ? commit('FILL_TAX_RULES', taxes.data) : null;
      }
    },
    async getPerPage({ commit }, term) {
      const taxes = await TaxRuleService.getPerPage(
        term,
        this.state.taxRule.orderSelected
      );
      taxes.code === 200 ? commit('FILL_TAX_RULES', taxes.data) : null;
    },
    async getTaxRules({ commit }, query) {
      const response = await TaxRuleService.getTaxes(query);
      response.code === 200 ? commit('FILL_TAX_RULES', response.data) : null;
    },
    async searchTax({ commit }, search) {
      const response = await TaxRuleService.search(search);
      response.code === 200 ? commit('FILL_TAX_RULES', response.data) : null;
    },
    async getIcms({ commit }) {
      const response = await TaxRuleService.listIcms();
      response.code === 200 ? commit('FILL_ICMS', response.data) : null;
    },
    async getIpi({ commit }) {
      const response = await TaxRuleService.listIpi();
      response.code === 200 ? commit('FILL_IPI', response.data) : null;
    },
    async getPisCofins({ commit }) {
      const response = await TaxRuleService.listPisCofins();
      response.code === 200 ? commit('FILL_PIS_COFINS', response.data) : null;
    },
    async getCfop({ commit }) {
      const response = await TaxRuleService.listCfop();
      response.code === 200 ? commit('FILL_CFOP', response.data) : null;
    }
  },
  getters: {
    taxRules: (state) => state.taxRules
  }
};
