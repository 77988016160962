<template>
  <div class="tag">
    <input
      v-model="model"
      type="checkbox"
      class="btn-check"
      :id="id"
      autocomplete="off"
    />
    <label class="btn btn-tag col-4" :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'EpChecked',
  props: ['label', 'id', 'checked'],
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$emit('update:checked', value);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-box {
  margin: 0 auto;

  .production {
    width: 40px;
    height: 24px;

    &:checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    &:focus {
      border-color: lighten($color: $primary-color, $amount: 0);
    }
  }

  .form-switch {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.table-striped {
  thead {
    th {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      font-family: $font-secondary;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  tbody {
    tr {
      td {
        padding-top: 12px;
        padding-bottom: 12px;

        a {
          margin-right: 24px;
        }
      }
    }
  }
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: #fafafa;
}

.box-table {
  border-top: 1px solid #dcdde4;
}

.select-all {
  color: #272a2c;
  font-family: $font-secondary;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;

  .form-check-input[type='checkbox'] {
    width: 24px;
    height: 24px;
    background-color: #dcdde4;

    &:checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    &:focus {
      border-color: lighten($color: $primary-color, $amount: 0);
    }
  }

  .form-check-label {
    margin-top: 5px;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  .btn-tag {
    display: unset;
    background: #ffffff;
    border: 1px solid #c0c5c8;
    border-radius: 56px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #5e666a;
    padding: 8px 16px;

    &:hover {
      background-color: $primary-color;
      border: 1px solid $primary-color;
      color: #fff;
    }
  }

  .btn-check:checked+.btn {
    background-color: $primary-color;
    border: none;
    color: white;
  }
}

.btn-add-product {
  width: 160px;
  height: 45px;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  span {
    font-family: $font-secondary;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    flex: 1 0 0;
  }

  em {
    font-size: 32px;
  }
}

.btn-cancel {
  width: 160px;
  height: 45px;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #4b5053;
  background: #ffffff;
  text-decoration: none !important;
  margin-right: 16px;

  em {
    font-size: 32px;
    color: #4b5053;
  }

  span {
    font-family: $font-secondary;
    font-weight: 700;
    font-size: 16px;
    color: #4b5053;
    text-decoration: none !important;
  }
}

.loading-box {
  --bs-bg-opacity: 0.4;
  left: 0;
  top: 0;
}

pre {
  text-align: left;
}
</style>
